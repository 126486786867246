// src/authService.js

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const signup = async (email, password) => {
  return axios.post(`${API_URL}/signup`, { email, password });
};

export const login = async (email, password) => {
  const res = await axios.post(`${API_URL}/login`, { email, password });
  const { access_token } = res.data;
  localStorage.setItem('token', access_token); // ✅ Save token
  return access_token;
};

export const logout = () => {
  localStorage.removeItem('token');
};

export const getToken = () => localStorage.getItem('token');
