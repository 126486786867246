import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);

  const token = localStorage.getItem('token');
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    axios.get(`${BASE_URL}/admin/users`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => setUsers(res.data))
    .catch(err => console.error('Failed to fetch users', err));
  }, []);

  return (
    <div className="p-8 bg-gray-900 text-white min-h-screen">
      <h1 className="text-3xl font-bold mb-6">👑 Admin Console</h1>
      <table className="w-full text-left bg-gray-800 rounded">
        <thead>
          <tr className="text-gray-400 border-b border-gray-700">
            <th className="p-2">Email</th>
            <th className="p-2">Verified</th>
            <th className="p-2">Signup Date</th>
          </tr>
        </thead>
        <tbody>
          {users.map(u => (
            <tr key={u.id} className="border-b border-gray-700">
              <td className="p-2">{u.email}</td>
              <td className="p-2">{u.verified ? '✅' : '❌'}</td>
              <td className="p-2">{new Date(u.created_at).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminDashboard;
