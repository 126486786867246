import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer'; // or Footer if you renamed it to uppercase

const features = [
  {
    title: "AI Trading Intelligence",
    icon: "🧠",
    description: "Our ML engine reads candle formations and volatility to generate precise buy/sell signals.",
  },
  {
    title: "Full-Market Scanner",
    icon: "📡",
    description: "Track over 130+ Binance US assets, sorted by probability and strategy match.",
  },
  {
    title: "Live Price Forecasting",
    icon: "📈",
    description: "Short-term projections with directional bias and trend indicators.",
  },
  {
    title: "Strategy Recognition",
    icon: "🎯",
    description: "Breakout, Trend, Range, Reversal — see exactly which strategy applies.",
  },
  {
    title: "Sentiment Analyzer",
    icon: "📢",
    description: "Detect social spikes across Twitter, Reddit, Telegram for each coin.",
  },
  {
    title: "Signal Dashboard",
    icon: "🧾",
    description: "Gauge-style dashboards highlight signal strength and strategy clarity.",
  },
  {
    title: "Real-time Alerts",
    icon: "📲",
    description: "Push and browser alerts when signals fire. Stay in the loop anywhere.",
  },
  {
    title: "Metrics & Backtesting",
    icon: "📊",
    description: "Measure strategy accuracy, confidence and drawdown over time.",
  },
  {
    title: "Built for Speed",
    icon: "⚡",
    description: "Fully responsive UI, fast API, and real-time refresh across dashboard.",
  },
];

const testimonials = [
  {
    name: "Bharath Mattaparti",
    title: "Pro Trader",
    quote: "Alypto nailed 4 breakout trades in one week. I stopped using 3 other tools.",
  },
  {
    name: "Vanya Ch",
    title: "Crypto Analyst",
    quote: "I was blown away by the dashboard clarity. It’s like TradingView but smarter.",
  },
  {
    name: "Saraswati B",
    title: "Swing Trader",
    quote: "The alerts helped me exit a dump right in time. This is a must-have tool.",
  },
];

const roadmap = [
  { phase: "✅ Q1", text: "Launch Live Signal Engine" },
  { phase: "✅ Q2", text: "Add Sentiment & Strategy AI" },
  { phase: "🛠️ Q3", text: "Add Portfolio Tracking & Watchlists" },
  { phase: "🚀 Q4", text: "Mobile App & Trading Bots (Beta)" },
];

const Home = () => {
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    AOS.init({ duration: 800 });
  }, []);

  return (
    <>
      {/* Background Layer */}
      <div className="fixed inset-0 bg-gradient-to-br from-blue-900 via-black to-purple-900 animate-pulse opacity-30 z-0 pointer-events-none" />

      {/* Main Content Layer */}
      <div className="relative z-10">
        <div className={`${darkMode ? 'bg-gray-950 text-white' : 'bg-white text-gray-900'} min-h-screen font-sans transition duration-300`}>

          {/* Navbar */}
          <Navbar />

          {/* Page content padding to avoid hiding behind fixed navbar */}
          <div className="pt-24">

            {/* Hero Section */}
            <div className="max-w-6xl mx-auto text-center px-4 pb-10">
              <h1 className="text-4xl font-bold mb-4">⚡ A Smarter Way to Predict Crypto</h1>
              <p className="text-gray-400 dark:text-gray-300 max-w-2xl mx-auto text-lg">
                Alypto uses real-time ML models to detect breakouts, reversals, and profitable crypto entries with speed and clarity.
              </p>
              <div className="mt-6 space-x-4">
                <Link to="/dashboard" className="bg-blue-600 px-6 py-2 rounded shadow text-white hover:bg-blue-700 transition">
                  Try Demo
                </Link>
                <Link to="/signup" className="border border-blue-500 px-6 py-2 rounded text-blue-400 hover:bg-blue-500 hover:text-white transition">
                  Get Started
                </Link>
              </div>
            </div>

            {/* Features Section */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto px-6 pb-20">
              {features.map((feat, idx) => (
                <div
                  key={idx}
                  data-aos="fade-up"
                  className="bg-gray-900 dark:bg-white dark:text-black border border-gray-800 dark:border-gray-300 rounded-xl p-6 hover:shadow-xl hover:scale-[1.02] transition-transform duration-300"
                >
                  <div className="text-3xl mb-3">{feat.icon}</div>
                  <h3 className="text-lg font-semibold text-blue-400 dark:text-blue-600">{feat.title}</h3>
                  <p className="text-gray-400 dark:text-gray-600 text-sm mt-2">{feat.description}</p>
                </div>
              ))}
            </div>

            {/* Testimonials */}
            <div className="bg-gray-900 dark:bg-gray-100 py-16">
              <h2 className="text-2xl text-center font-bold mb-10 text-blue-400 dark:text-blue-600">💬 What Traders Say</h2>
              <div className="flex flex-wrap justify-center gap-8 px-6">
                {testimonials.map((t, i) => (
                  <div key={i} className="max-w-sm bg-gray-800 dark:bg-white dark:text-gray-800 border border-gray-700 dark:border-gray-300 rounded-lg p-6 shadow-md">
                    <p className="text-md italic mb-4">“{t.quote}”</p>
                    <p className="font-semibold">{t.name}</p>
                    <p className="text-sm text-gray-400">{t.title}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Roadmap */}
            <div className="py-16 px-6 max-w-4xl mx-auto">
              <h2 className="text-2xl text-center font-bold mb-8 text-blue-400 dark:text-blue-600">🗺️ Roadmap</h2>
              <ul className="space-y-4 text-gray-300 dark:text-gray-700">
                {roadmap.map((r, i) => (
                  <li key={i} className="flex items-start space-x-3">
                    <span className="text-xl">{r.phase}</span>
                    <span className="text-sm">{r.text}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* Footer */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
