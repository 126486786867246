// Dashboard.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SignalCard from '../utils/SignalCard';
import { parseJwt } from '../utils/utils';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UserDashboard = () => {
  const [symbol, setSymbol] = useState('');
  const [singleResult, setSingleResult] = useState(null);
  const [allResults, setAllResults] = useState([]);
  const [loadingSingle, setLoadingSingle] = useState(false);
  const [loadingAll, setLoadingAll] = useState(true);
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = parseJwt(token);
      setUserEmail(decoded?.sub || 'User');
    }
  }, []);

  const fetchSinglePrediction = async () => {
    if (!symbol) return;
    try {
      setLoadingSingle(true);
      const res = await axios.get(`${BASE_URL}/realtime_predict?symbol=${symbol.toUpperCase()}`);
      setSingleResult(res.data);
    } catch (err) {
      console.error('Error fetching single prediction:', err);
      setSingleResult(null);
    } finally {
      setLoadingSingle(false);
    }
  };

  const fetchAllPredictions = async () => {
    try {
      setLoadingAll(true);
      const res = await axios.get(`${BASE_URL}/realtime_predictall`);
      setAllResults(res.data);
    } catch (err) {
      console.error('Error fetching all predictions:', err);
      setAllResults([]);
    } finally {
      setLoadingAll(false);
    }
  };

  useEffect(() => {
    fetchAllPredictions();
    const interval = setInterval(fetchAllPredictions, 300000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6 font-sans">
      <div className="max-w-6xl mx-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">👋 Welcome, {userEmail}</h2>
          <button
            onClick={() => {
              localStorage.removeItem('token');
              navigate('/login');
            }}
            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
          >
            Logout
          </button>
        </div>

        <h1 className="text-3xl font-bold mb-6 text-center text-white">📈 Crypto Signal Dashboard</h1>

        <div className="flex justify-center mb-6 gap-4">
          <input
            type="text"
            value={symbol}
            onChange={(e) => setSymbol(e.target.value)}
            placeholder="Enter symbol (e.g., BTC/USDT)"
            className="border border-gray-700 bg-gray-800 text-white px-4 py-2 rounded w-64 shadow"
          />
          <button onClick={fetchSinglePrediction} className="bg-blue-600 text-white px-4 py-2 rounded shadow">
            Predict
          </button>
        </div>

        {loadingSingle ? (
          <p className="text-center text-gray-400">Loading prediction...</p>
        ) : singleResult ? (
          <div className="mb-8 bg-gray-800 p-4 rounded shadow text-white">
            <h2 className="text-xl font-semibold mb-2">Prediction for {singleResult.symbol}</h2>
            <p><strong>Profit Probability:</strong> {(singleResult.profit_probability * 100).toFixed(2)}%</p>
            <p><strong>Matching Strategies:</strong> {singleResult.matching_strategies?.join(', ') || 'None'}</p>
            <p>
              <strong>Signal:</strong>{' '}
              <span className={singleResult.signal === 'Buy' ? 'text-green-400 font-semibold' : 'text-red-500 font-semibold'}>
                {singleResult.signal}
              </span>
            </p>
          </div>
        ) : null}

        <h2 className="text-2xl font-bold mb-4">Top Coin Predictions</h2>

        {loadingAll ? (
          <p className="text-center text-gray-400">Loading predictions...</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {allResults.map((coin, idx) => (
              <SignalCard
                key={idx}
                symbol={coin.symbol}
                profit_probability={coin.profit_probability}
                strategies={coin.strategies}
                signal={coin.signal}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDashboard;
