import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("Verifying...");
  const token = searchParams.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    const verify = async () => {
      try {
        await axios.get(`${BASE_URL}/verify-email?token=${token}`);
        setMessage("✅ Email verified successfully. Redirecting to login...");
        setTimeout(() => navigate("/login"), 3000); // ✅ Auto-redirect after 3 seconds
      } catch (err) {
        setMessage("❌ Verification failed or token expired.");
      }
    };

    if (token) verify();
    else setMessage("Invalid verification link.");
  }, [token, navigate]);

  return (
    <div className="min-h-screen bg-gray-950 text-white flex items-center justify-center p-6">
      <div className="bg-gray-900 p-6 rounded shadow-md text-center max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">Email Verification</h2>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default VerifyEmail;
