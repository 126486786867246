import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-black text-gray-400 px-4 py-12">
      {/* Centered + consistent width */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-sm">

        {/* Brand Section */}
        <div>
          <h4 className="text-white text-lg font-semibold mb-4">Alypto</h4>
          <p className="text-gray-500 leading-relaxed">
            AI-driven crypto signal platform for smarter trading.
            Stay ahead with predictive insights, live alerts, and strategy analytics.
          </p>
        </div>

        {/* Connect Section */}
        <div>
          <h4 className="text-white text-lg font-semibold mb-4">Connect</h4>
          <ul className="space-y-2">
            <li>
              <a
                href="https://twitter.com"
                className="hover:text-blue-400"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                href="https://discord.com"
                className="hover:text-blue-400"
                target="_blank"
                rel="noreferrer"
              >
                Discord
              </a>
            </li>
            <li>
              <a href="mailto:support@alypto.com" className="hover:text-blue-400">
                Email Us
              </a>
            </li>
            <li>
              <Link to="/privacy" className="hover:text-blue-400">
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>

        {/* More Section */}
        <div>
          <h4 className="text-white text-lg font-semibold mb-4">More</h4>
          <ul className="space-y-2">
            <li>
              <Link to="/terms" className="hover:text-blue-400">
                Terms of Service
              </Link>
            </li>
            <li>
              <Link to="/security" className="hover:text-blue-400">
                Security
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Bottom Text */}
      <div className="mt-12 text-center text-xs text-gray-600">
        © {new Date().getFullYear()} Alypto. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
