import React from 'react';

const SignalCard = ({ symbol, profit_probability, strategies, signal }) => {
  return (
    <div className="bg-gray-800 rounded-lg shadow-lg p-4 text-white hover:shadow-xl transition-all">
      <div className="flex justify-between items-center mb-3">
        <div className="text-xl font-bold">{symbol}</div>
        <span className={
          signal === 'Buy'
            ? 'bg-green-600 text-white px-3 py-1 text-sm rounded-full'
            : 'bg-red-500 text-white px-3 py-1 text-sm rounded-full'
        }>
          {signal}
        </span>
      </div>
      <div className="mb-2 text-sm text-gray-300">Profit Probability:</div>
      <div className="text-lg font-semibold text-blue-400">{(profit_probability * 100).toFixed(2)}%</div>
      <div className="mt-2 text-sm text-gray-400">Strategies:</div>
      <div className="text-sm">{strategies?.join(', ') || 'None'}</div>
    </div>
  );
};

export default SignalCard;