import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Subscribe = () => {
  const handleSubscribe = async () => {
    const stripe = await stripePromise;
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/stripe/create-checkout-session`);
    window.location.href = res.data.url;
  };

  return (
    <div className="text-center mt-10">
      <h1 className="text-2xl font-bold text-white mb-4">Upgrade to Alypto Pro</h1>
      <button
        onClick={handleSubscribe}
        className="bg-purple-600 px-6 py-3 rounded text-white hover:bg-purple-700"
      >
        Upgrade – $99
      </button>
    </div>
  );
};

export default Subscribe;
