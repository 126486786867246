import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './home/Home';
import Signup from './auth/Signup';
import Login from './auth/Login';
import PrivateRoute from './utils/PrivateRoute';
import VerifyEmail from './auth/VerifyEmail';
import AdminDashboard from './admin/AdminDashboard';
import Subscribe from './subscribe/Subscribe';
import Success from './subscribe/Success';
import Cancel from './subscribe/Cancel';
import UserDashboard from './userapp/UserDashboard'; 
import Contact from './support/Contact';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/userdashboard"
          element={
            <PrivateRoute>
              <UserDashboard />
            </PrivateRoute>
          }
        />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
