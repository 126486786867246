import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const menuItems = [
  {
    label: 'Services',
    items: [
      { label: 'Cryptocurrencies', href: '/services/crypto' },
      { label: 'Prices', href: '/services/prices' },
      { label: 'User Guide', href: '/guide' },
    ],
  },
  {
    label: 'Performance',
    items: [
      { label: 'Backtesting', href: '/performance/backtest' },
      { label: 'Strategies', href: '/performance/strategies' },
    ],
  },
  {
    label: 'About',
    items: [
      { label: 'Contact Us', href: '/contact' },
      { label: 'About Us', href: '/about' },
      { label: 'FAQ', href: '/faq' },
    ],
  },
];

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (label) => {
    setOpenDropdown((prev) => (prev === label ? null : label));
  };

  return (
    <>
      {/* Top Navbar */}
      <nav className="fixed top-0 left-0 right-0 z-50 bg-black bg-opacity-90 backdrop-blur-md text-white shadow-md">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          {/* Logo */}
          <Link to="/" className="flex items-center text-blue-400 font-bold text-xl">
            <img src="/logo.png" alt="Logo" className="h-8 mr-2" />
            <span>Alypto</span>
          </Link>

          {/* Hamburger (mobile) */}
          <button
            onClick={() => setMobileOpen(true)}
            className="text-white md:hidden text-2xl"
          >
            ☰
          </button>

          {/* Desktop Nav Items */}
          <div className="hidden md:flex space-x-6 items-center">
            <Link to="/" className="hover:text-blue-400 transition">Home</Link>

            {menuItems.map((menu) => (
              <div key={menu.label} className="relative group">
                <button className="px-4 py-2 hover:text-blue-400 transition flex items-center">
                  {menu.label}
                  <span className="ml-1">▼</span>
                </button>
                <div className="absolute left-0 top-full mt-1 bg-gray-800 border border-gray-700 rounded shadow-md py-2 z-50 hidden group-hover:block min-w-[180px]">
                  {menu.items.map((item, idx) => (
                    <Link
                      key={idx}
                      to={item.href}
                      className="block px-4 py-2 text-sm text-white hover:bg-gray-700 transition"
                    >
                      {item.label}
                    </Link>
                  ))}
                </div>
              </div>
            ))}

            <Link to="/login" className="hover:text-blue-400 transition">Sign in</Link>
            <Link
              to="/signup"
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            >
              Sign Up
            </Link>
          </div>
        </div>
      </nav>

      {/* Overlay */}
      {mobileOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setMobileOpen(false)}
        />
      )}

      {/* Mobile Sidebar Menu */}
      <div
        className={`fixed top-0 left-0 h-full w-72 bg-gray-900 text-white z-50 transform transition-transform duration-300 ${
          mobileOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="flex justify-between items-center px-4 py-4 border-b border-gray-700">
          <Link
            to="/"
            className="flex items-center text-blue-400 font-bold text-xl"
            onClick={() => setMobileOpen(false)}
          >
            <img src="/logo.png" alt="Logo" className="h-8 mr-2" />
            Alypto
          </Link>
          <button
            onClick={() => setMobileOpen(false)}
            className="text-white text-2xl"
          >
            ×
          </button>
        </div>

        <nav className="flex flex-col px-4 py-2 space-y-2 overflow-y-auto">
          <Link
            to="/"
            onClick={() => setMobileOpen(false)}
            className="py-2 border-b border-gray-700"
          >
            Home
          </Link>

          {menuItems.map((menu) => (
            <div key={menu.label}>
              <button
                onClick={() => toggleDropdown(menu.label)}
                className="w-full flex justify-between items-center py-2 border-b border-gray-700 text-left"
              >
                <span>{menu.label}</span>
                <span>{openDropdown === menu.label ? '▲' : '▼'}</span>
              </button>
              {openDropdown === menu.label && (
                <div className="pl-4">
                  {menu.items.map((item, idx) => (
                    <Link
                      key={idx}
                      to={item.href}
                      onClick={() => setMobileOpen(false)}
                      className="block py-2 text-sm border-b border-gray-800"
                    >
                      {item.label}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}

          <Link
            to="/login"
            onClick={() => setMobileOpen(false)}
            className="py-2 border-b border-gray-700"
          >
            Sign in
          </Link>
          <Link
            to="/signup"
            onClick={() => setMobileOpen(false)}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded text-center mt-2"
          >
            Sign Up
          </Link>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
